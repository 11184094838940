import React, { Component } from "react";
import toast from "react-hot-toast";
import baseUrl from "../api/baseUrl";
import "../styles/auth.css";
import AdminAuth from "../utility/auth";
import axios from "axios";
import { AdminType } from "../utility/constant";

interface AuthProps {
  history: any;
}
interface AuthState {
  email: string;
  password: string;
  loading: boolean;
}

export default class Auth extends Component<AuthProps, AuthState> {
  AuthInstance: AdminAuth;
  constructor(props: AuthProps) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
    };
    this.AuthInstance = AdminAuth.getInstance();
  }
  handleLogin = async (e: any) => {
    e.preventDefault();
    this.setState({ loading: true });
    try {
      const res = await baseUrl.post("/admin/login", {
        email: this.state.email.toLowerCase(),
        password: this.state.password,
      });
      console.log(
        "res.data?.data.type === AdminType.SuperAdmin",
        res.data?.data.type === AdminType.SuperAdmin
      );

      if (res.data.isSuccess && res.data.data && res.data?.data.token) {
        this.setState({ loading: true });
        this.AuthInstance.setAuth(res.data.isSuccess);
        this.AuthInstance.token = res.data?.data.token;
        this.AuthInstance.setAdminType(res.data?.data?.admin?.type);
        this.AuthInstance.depos = res.data?.data?.admin?.depos;
        baseUrl.interceptors.request.use((config: any) => {
          config.headers.Authorization = AdminAuth.getInstance().token!;
          return config;
        });
        toast.success("successfully logged in as admin");
        setTimeout(() => {
          this.props.history.push("dashboard");
        }, 1000);
      } else {
        toast.success("email or password is incorrect");
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    return (
      <div className="auth-container">
        <form className="form" onSubmit={this.handleLogin}>
          <div className="auth-logo">
            <img src={"dtc.png"} alt="logo" />
          </div>
          <p className="form-title">Login</p>
          <input
            placeholder="Enter Email"
            onChange={(e) => {
              this.setState({ email: e.target.value });
            }}
            type={"email"}
            required
          />
          <input
            placeholder="Enter Password"
            onChange={(e) => {
              this.setState({ password: e.target.value });
            }}
            type={"password"}
            required
          />
          <button>Login</button>
        </form>
      </div>
    );
  }
}
