import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import moment from "moment";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QrDetail(props: any) {
  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  console.log("row", props.row);

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            padding: 10,
          }}
        >
          <h3
            style={{
              margin: "10px 0",
            }}
          >
            {`QR code(${props.qrdata.qrCode.shortId}) Data`}
          </h3>
        </div>
        <div
          style={{
            padding: 10,
            fontWeight: 500,
          }}
        >
          <p>
            Linked On :{" "}
            {moment(props.qrdata.createdAt).format("YYYY-MM-DD/hh:mm:ss A")}
          </p>
        </div>
        <div
          style={{
            padding: 10,
            fontWeight: 500,
          }}
        >
          <h3>Vehicle Details</h3>
          <div
            style={{
              padding: "5px 0",
            }}
          >
            <p>Vehicle Number : {props.qrdata.vehicle.vehicleNumber}</p>
            {/* <p>model : {props.qrdata.vehicle.model}</p>
            <p>make : {props.qrdata.vehicle.make}</p> */}
            {/* <p>vehicle state : {props.qrdata.vehicleState}</p> */}
          </div>
        </div>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
