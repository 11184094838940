import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { addDepo, linkQrWithVehicle } from "../api";
import QrDetail from "../components/QrDetails";
import { MdClose, MdPlusOne } from "react-icons/md";
import { AiOutlinePlusCircle } from "react-icons/ai";

const AddDepo = (props: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState<any>([]);
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [emailCount, setEmailCount] = useState(1);
  const [emailCountArr, setEmailCountArr] = useState(
    new Array(emailCount).fill("")
  );

  const [vehicleId, setVehicleId] = useState("");
  const [open, setOpen] = useState(false);
  const [qrdata, setQrData] = useState(null);
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setName("");
    setEmail("");
    setNumber("");
    setAddress("");
    setContactPerson("");
  };


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      const res = await addDepo(name, address, contactPerson, number, emails);
      if (res?.isSuccess) {
        toast.success("Depot added");
      } else {
        toast.error("Failed to add depot");
      }
      setLoading(false);
      setVehicleId("");
    } else {
      return;
    }
  };

  const handleRemoveEmail = (indexToDelete: number) => {
    const filter = emails.filter((_: never, i: number) => {
      return indexToDelete !== i;
    });
    setEmails(filter);
  };
  return (
    <div
      className="auth-container"
      style={{
        backgroundImage: "linear-gradient(to left, #fff,#fff)",
      }}
    >
      <form className="form" onSubmit={handleSubmit}>
        <p className="form-title">Add Depot</p>
        <input
          placeholder="Enter Depot Name"
          onChange={(e) => {
            setName(e.target.value);
          }}
          type={"text"}
          value={name}
          required
        />
        <input
          placeholder="Enter Address"
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          type={"text"}
          value={address}
          required
        />
        <input
          placeholder="Enter contactPerson"
          onChange={(e) => {
            setContactPerson(e.target.value);
          }}
          type={"text"}
          value={contactPerson}
          required
        />
        <input
          placeholder="Enter phoneNumber"
          onChange={(e) => {
            setNumber(e.target.value);
          }}
          type={"number"}
          value={number}
          required
        />
        {emailCountArr.map((_, i) => (
          <div className="multiple_wrap">
            <input
              placeholder="Enter emails"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type={"email"}
              value={email}
            />
            <AiOutlinePlusCircle
              size={30}
              onClick={() => {
                setEmails([...emails, email]);
                setEmail("");
              }}
            />
          </div>
        ))}
        <div className="email_wrap">
          {emails.map((email: string, i: number) => (
            <p className="email" key={i}>
              {email}
              <span onClick={() => handleRemoveEmail(i)}>
                <MdClose />
              </span>
            </p>
          ))}
        </div>
        <button>
          {loading ? <CircularProgress size={17} color="inherit" /> : "Submit"}
        </button>
      </form>
      {qrdata && !loading && (
        <QrDetail qrdata={qrdata} open={open} setOpen={setOpen} />
      )}
    </div>
  );
};

export default AddDepo;
