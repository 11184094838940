import moment from "moment";
export const COLUMNS = [
  {
    Header: "s.no",
    Cell: (props) => <p>{parseInt(props.row.id) + 1}</p>, //RDT provides index by default
  },
  {
    Header: "Breakdown Time",
    accessor: "createdAt",
    Cell: ({ value }) => {
      return moment(value).format("DD-MM-YYYY hh:mm A");
    },
  },

  {
    Header: "Vehicle No",
    Cell: (props) => {
      console.log("props.row.original", props.row);
      return props.row.original?.vehicle?.vehicleNumber ?? "none";
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "Vehicle Depot",
    Cell: (props) => {
      return props.row.original?.vehicle?.depo?.name ?? "none";
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "Lat. Long.",
    Cell: (props) => {
      const lat =
        props.row.original.latitude ?? props.row.original.latitudeFromDevice;
      const log =
        props.row.original.longitude ?? props.row.original.longitudeFromDevice;
      return lat + "," + log;
    },
    Filter: () => {
      return null;
    },
  },

  {
    Header: "Map Link",
    Cell: (props) => {
      const lat =
        props.row.original.latitude ?? props.row.original.latitudeFromDevice;
      const log =
        props.row.original.longitude ?? props.row.original.longitudeFromDevice;
      return (
        <a
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${lat}%2C${log}`}
          rel="noreferrer"
        >
          https://www.google.com/maps/search/?api=1&query=${lat}%2C${log}
        </a>
      );
    },
  },
];
