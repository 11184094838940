import moment from "moment";
export const COLUMNS = [
  {
    Header: "name",
    accessor: "name",
    Cell: ({ value }) => {
      return value.substring(0, 12);
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "phoneNo",
    accessor: "phoneNo",
    Cell: ({ value }) => {
      return value.substring(0, 50);
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "city",
    accessor: "city",
    Filter: () => {
      return null;
    },
  },
  {
    Header: "createdAt",
    accessor: "createdAt",
    Cell: ({ value }) => {
      return moment(new Date(value)).format("DD-MM-YYYY hh:mm A");
    },
  },
  {
    Header: "price",
    // accessor: "finalPrice",
    Cell: ({ row }) => {
      return `${row.original?.sku.currency ?? "INR"} ${row.original.finalPrice}(${
        row.original.quantity
      })`;
    },
  },
  {
    Header: "orderStatus",
    accessor: "orderStatus",
  },
  {
    Header: "productType",
    accessor: "productType",
  },
];
