import React, { Component } from "react";
import { QRCodeSVG } from "qrcode.react";
import { ReactComponent as DoorViLogo } from "../assets/img/doorvi.svg";
import { ReactComponent as ScanToCall } from "../assets/img/scantocall.svg";
import { ReactComponent as VehicleBorder } from "../assets/img/ract_border.svg";
import { ReactComponent as BorderBottom } from "../assets/img/border_bottom.svg";
import { ReactComponent as Ring } from "../assets/img/call.svg";

interface QRGenratorProps {
  value: string;
  index: number;
  qr: any;
  qrType: string;
}

export default class QRGenrator extends Component<QRGenratorProps, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    console.log(this.props.qrType, "type");

    return (
      <>
        <div className="qr-wrap">
          <p className="qr-id">{"No " + this.props.qr.shortId}</p>
          <p className="qr-val">
            <Ring
              style={{
                width: 22,
                height: 20,
              }}
            />
          </p>

          <QRCodeSVG
            size={182}
            style={{
              margin: "5px 0",
            }}
            className="qrcode"
            value={this.props.value}
          />
          <div className="qr-footer">
            <p>Scan QR Code</p>
            <p>Contact Hotel Staff</p>
            <BorderBottom
              style={{
                marginTop: 3,
              }}
            />
          </div>
          <ul className="qr-notes">
            <li>Please Do Not Knock or Ring The Bell.</li>
            <li>Scan Code Using Camera or QR Scanner App</li>
          </ul>
          {/* <div className="footer-logo_wrap"> */}
          <DoorViLogo className="footer-logo" />
        </div>
      </>
    );
  }
}
