import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { addVehicle, getAllDepo } from "../api";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const AddVehicle = (props: any) => {
  const [vehicleno, setVehicleNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [depos, setDepos] = useState([]);
  const [depoid, setDepoId] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      const res = await addVehicle(vehicleno, depoid);
      if (res.isSuccess) {
        toast.success("Vechicle added");
      } else {
        toast.error("something went wrong");
      }
      setLoading(false);
      setVehicleNo("");
      setDepoId("");
    } else {
      return;
    }
  };

  const getDepos = async () => {
    const depos = await getAllDepo();
    if (depos && depos.data && depos.data.depos) {
      setDepos(depos.data.depos);
    } else {
      toast.error("depot not found");
    }
    console.log("depos", depos.data);
  };

  useEffect(() => {
    getDepos();
  }, []);

  return (
    <div
      className="auth-container"
      style={{
        backgroundImage: "linear-gradient(to left, #fff,#fff)",
      }}
    >
      <form className="form" onSubmit={handleSubmit}>
        <p className="form-title">Add Vehicle</p>
        <input
          placeholder="Enter Vehicle Number"
          onChange={(e) => {
            setVehicleNo(e.target.value);
          }}
          type={"text"}
          value={vehicleno}
          required
        />
        <FormControl
          style={{
            width: "90%",
            fontFamily: "inherit",
            margin: "15px 0",
            borderRadius: 10,
          }}
        >
          <InputLabel
            style={{
              fontFamily: "inherit",
            borderRadius: 10,
            }}
            id="demo-simple-select-label"
          >
            Select Depot
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={depoid}
            label="Select Depot"
            onChange={(e) => {
              setDepoId(e.target.value);
            }}
            style={{
              fontFamily: "inherit",
              // border: "none",
            borderRadius: 10,
            }}

          >
            {depos.map((depo: any) => (
              <MenuItem value={depo._id}>{depo.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <button>
          {loading ? <CircularProgress size={17} color="inherit" /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default AddVehicle;
