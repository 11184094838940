import React, { Component } from "react";
import { QRCodeSVG } from "qrcode.react";
// import DoorViLogo  from "/dtc.png";
import { ReactComponent as ScanToCall } from "../assets/img/scantocall.svg";
import { ReactComponent as VehicleBorder } from "../assets/img/ract_border.svg";
import { ReactComponent as BorderBottom } from "../assets/img/border_bottom.svg";
import { ReactComponent as Ring } from "../assets/img/call.svg";

interface QRGenratorProps {
  value: string;
  index: number;
  qr: any;
  qrType: string;
  sorid: string;
}

export default class QRGenrator extends Component<QRGenratorProps, {}> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    console.log(this.props.qrType, "type");
    return (
      <>
        <div className="qr-wrap-vehicle">
          {/* <img src={VehicleBorderPng} className="borderImg" /> */}
          {/* <VehicleBorder className="borderImg" /> */}

          <p className="qr-id" style={{ right: 15, bottom: 5 }}>
            {"No " + this.props.qr.shortId}
          </p>
          <QRCodeSVG
            size={120}
            style={{
              margin: "5px 0",
            }}
            className="qrcode"
            value={this.props.value}
          />

          <div className="qr-note-vehicle">
            <p>In case of</p>
            <p>BUS BREAKDOWN / EMERGENCY</p>
            <BorderBottom
              style={{
                marginTop: 3,
              }}
            />
          </div>
          <div className="qr-footer">
            <p>Scan QR Code</p>
            <p>To Contact HQ</p>
          </div>
          <>
            <img
              src="/logo2.png"
              className="footer-logo"
              style={{ bottom: 3 }}
            />
          </>
        </div>
      </>
    );
  }
}
