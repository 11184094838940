import React, { Component } from "react";
import QRGenrator from "../components/QRGenrator";
import "../styles/qr.css";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import baseUrl, { uris } from "../api/baseUrl";
import BTDCQRGenrator from "../components/qrtypes/BTDC_QR";
import HotelQRGenrator from "../components/HotelQr";
interface QRHandlerProps {
  history: any;
}

interface QRHandlerState {
  loading: boolean;
  imagesComponentRef: Array<any>;
  inputValue: number;
  qrs: Array<any>;
  progress: any;
}
export default class QRHandler extends Component<
  QRHandlerProps,
  QRHandlerState
> {
  private qrContainerRef: any;
  private gapAfterNoOfElement: any;
  constructor(props: QRHandlerProps) {
    super(props);
    this.state = {
      loading: false,
      imagesComponentRef: [],
      inputValue: 0,
      qrs: [],
      progress: 0,
    };
    this.gapAfterNoOfElement = 4 * 4;
    this.qrContainerRef = React.createRef();
  }

  getQrType = () => {
    switch (this.props.history.location.state.qrCategory) {
      case "BTDC":
        return "home";
      case "vehicle":
        return "vehicle";
      default:
        return "home";
    }
  };
  componentDidMount() {
    let { number, copy } = this.props.history.location.state;
    if (copy < 1) {
      copy = 1;
    }
    this.setState({ loading: true });

    this.fetchQR(number, copy, this.getQrType());
  }

  fetchQR = (numberOfQrCodes: number, copy: number, qrCategory: string) => {
    console.log(copy);

    baseUrl
      .post(
        `/qrcode/generateQRCode/${numberOfQrCodes}`,
        {},
        {
          headers: {},
          onUploadProgress: (progress) => {
            const total = (progress.loaded / progress.total) * 100;
            this.setState({ progress: total });
          },
        }
      )
      .then((response) => {
        const finalQrs: any[] = [];
        response.data.data.forEach((qr: any) => {
          for (let i = 1; i <= copy; i++) {
            finalQrs.push(qr);
          }
        });
        this.setState({ qrs: finalQrs, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  render() {
    console.log(
      "this.props.history.location.state.qrCategory",
      this.props.history.location.state.qrCategory
    );

    return (
      <>
        {this.state.loading && (
          <Modal>
            <Loader progress={this.state.progress} />
          </Modal>
        )}
        {(this.props.history.location.state.qrCategory === "DTC" ||
          this.props.history.location.state.qrCategory === "vehicle") && (
          <div
            className={"qr-grid-vehicle"}
            style={{
              backgroundColor: "#fff",
            }}
          >
            {this.state.qrs.map((qr, i) => (
              <QRGenrator
                index={i}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}&${qr.shortId}`}
                sorid={`${uris.callProductionUri}${qr.shortId}`}
                key={i}
                qr={qr}
              />
            ))}
          </div>
        )}
        {this.props.history.location.state.qrCategory === "BTDC" && (
          <div className="qr-grid">
            {this.state.qrs.map((qr, i) => (
              <BTDCQRGenrator
                index={i}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}`}
                key={i}
                qr={qr}
              />
            ))}
          </div>
        )}
        {this.props.history.location.state.qrCategory === "hotel" && (
          <div
            className="qr-grid"
            style={{
              backgroundColor: "#067eee",
            }}
          >
            {this.state.qrs.map((qr, i) => (
              <HotelQRGenrator
                index={i}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}`}
                key={i}
                qr={qr}
              />
            ))}
          </div>
        )}
      </>
    );
  }
}
