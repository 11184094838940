import React, { Component } from "react";
import "../styles/dashboard.css";
import { MdLogout, MdOutlineQrCode, MdOutlineAddLink } from "react-icons/md";
import { BiCar, BiUserCheck } from "react-icons/bi";
import { BsFileCode } from "react-icons/bs";
import Table from "../Table/Table";
import QRDialog from "../components/QRDialog";
import AdminAuth from "../utility/auth";
import { fetchOrders } from "../api";
import { CircularProgress, Dialog } from "@mui/material";
import EventEmitter from "events";
import UserInfo from "../components/UserInfo";
import { FaBus, FaDatabase } from "react-icons/fa";
import QrData from "../components/QrData";
import LinkQR from "./LinkQr";
import AddVehicle from "./AddVehicle";
import VehicelTable from "../Table/VehicleData/Table";
import Auth from "./Auth";
import EditVehicle from "./EditVehicle";
import { AdminType } from "../utility/constant";
import AddDepo from "./AddDepo";
import DepoTable from "../Table/DepoData/Table";
import EditDepo from "./EditDepo";

const em = new EventEmitter();
const MenuItem = (props: any) => {
  return (
    <div className="item-wrap">
      <div className="left-menu_item" onClick={props.onClick}>
        <props.Icon />
        <p>{props.text}</p>
      </div>
    </div>
  );
};

interface DashBoardState {
  qrDialogOpen: boolean;
  loading: boolean;
  showEdit: boolean;
  showDepoEdit: boolean;
  orders: any;
  index: number;
}
interface DashBoardProps {
  history: any;
}

export default class DashBoard extends Component<
  DashBoardProps,
  DashBoardState
> {
  AuthInstance: AdminAuth;
  editVehicleData: any | undefined;
  editDepoData: any | undefined;
  constructor(props: DashBoardProps) {
    super(props);
    this.state = {
      qrDialogOpen: false,
      orders: [],
      loading: false,
      showEdit: false,
      showDepoEdit: false,
      index: 0,
    };
    this.AuthInstance = AdminAuth.getInstance();
    this.editVehicleData = undefined;
    this.editDepoData = undefined;
  }

  fetchOrdersData = async () => {
    this.setState({ loading: true });
    const data = await fetchOrders();
    this.setState({ orders: data, loading: false });
  };

  async componentDidMount() {
    if (!AdminAuth.getInstance().getAuth()) {
      window.location.href = "/";
    } else {
      this.AuthInstance.eventEmitter.addListener("showEdit", (data: any) => {
        this.setState({ showEdit: true });
        this.editVehicleData = data;
      });
      this.AuthInstance.eventEmitter.addListener(
        "showDepoEdit",
        (data: any) => {
          this.setState({ showDepoEdit: true });
          this.editDepoData = data;
        }
      );
    }
  }

  handleOrderDetail = async () => {
    this.setState({ index: 2 });
    await this.fetchOrdersData();
  };
  handleUserData = async () => {
    console.log("dwadwa");
    if (this.state.index !== 0) {
      this.setState({ index: 0 });
    } else {
      em.emit("onRefresh", "");
    }
  };

  handleOpen = () => {
    this.setState({ qrDialogOpen: true });
  };
  handleClose = () => {
    this.setState({ qrDialogOpen: false });
  };
  handlecloseEditVehicle = () => {
    this.setState({ showEdit: false });
  };
  handlecloseEditDepo = () => {
    this.setState({ showDepoEdit: false });
  };
  handleLogout = () => {
    window.location.href = "/";
  };
  render() {
    return (
      <div
        className="container"
        style={{
          display: "flex",
        }}
      >
        <QRDialog
          handleClose={this.handleClose}
          handleOpen={this.handleOpen}
          open={this.state.qrDialogOpen}
          history={this.props.history}
        />
        <div className="left">
          <>
            <img className="left-logo" src="dtc.png" alt="logo" />
            {/* <p className="adminText">Admin</p> */}
          </>
          <div className="left-menu">
            {(this.AuthInstance.adminType === AdminType.Depo ||
              this.AuthInstance.adminType === AdminType.SuperAdmin) && (
              <>
                <MenuItem
                  text="Breakdown Data"
                  Icon={() => <FaDatabase size={22} color="#030303" />}
                  onClick={() => {
                    this.handleUserData();
                  }}
                />
              </>
            )}
            <>
              <MenuItem
                text="Vehicle Data"
                Icon={() => <FaBus size={22} color="#030303" />}
                onClick={() => {
                  this.state.index !== 5
                    ? this.setState({ index: 5 })
                    : em.emit("onVehicleRefresh", "");
                }}
              />

              {(this.AuthInstance.adminType === AdminType.Depo ||
                this.AuthInstance.adminType === AdminType.SuperAdmin) && (
                <>
                  <MenuItem
                    text="Add Vehicle"
                    Icon={() => <BiCar size={22} color="#030303" />}
                    onClick={() => {
                      this.setState({ index: 4 });
                    }}
                  />
                </>
              )}
              {(this.AuthInstance.adminType === AdminType.Depo ||
                this.AuthInstance.adminType === AdminType.SuperAdmin) && (
                <MenuItem
                  text="Depo Data"
                  Icon={() => <BiCar size={22} color="#030303" />}
                  onClick={() => {
                    this.state.index !== 7
                      ? this.setState({ index: 7 })
                      : em.emit("onDepoRefresh", "");
                  }}
                />
              )}
              {this.AuthInstance.adminType === AdminType.SuperAdmin && (
                <MenuItem
                  text="Add Depo"
                  Icon={() => <BiCar size={22} color="#030303" />}
                  onClick={() => {
                    this.setState({ index: 6 });
                  }}
                />
              )}
              {/* <MenuItem
              text="QR Code Data"
              Icon={() => <BsFileCode size={20} color="#030303" />}
              onClick={() => {
                this.setState({ index: 1 });
              }}
            /> */}
              {this.AuthInstance.adminType === AdminType.SuperAdmin && (
                <>
                  <MenuItem
                    text="Link QR Code"
                    Icon={() => <MdOutlineAddLink size={20} color="#030303" />}
                    onClick={() => {
                      this.setState({ index: 3 });
                    }}
                  />
                  <MenuItem
                    text="Generate QR Code"
                    onClick={this.handleOpen}
                    Icon={() => <MdOutlineQrCode size={20} color="#030303" />}
                  />
                </>
              )}
              <MenuItem
                text="Logout"
                onClick={this.handleLogout}
                Icon={() => <MdLogout size={20} color="#030303" />}
              />
            </>
          </div>

          <div className="left-profile"></div>
        </div>
        <div className="right">
          {this.state.index === 0 && <UserInfo em={em} />}
          {this.state.index === 5 && <VehicelTable em={em} />}
          {this.state.index === 1 && <QrData />}
          {this.state.index === 4 && <AddVehicle />}
          <Dialog
            style={{
              fontFamily: "inherit",
            }}
            open={this.state.showEdit}
            onClose={this.handlecloseEditVehicle}
          >
            <EditVehicle vehicleData={this.editVehicleData} />
          </Dialog>
          <Dialog
            style={{
              fontFamily: "inherit",
            }}
            open={this.state.showDepoEdit}
            onClose={this.handlecloseEditDepo}
          >
            <EditDepo
              onClose={this.handlecloseEditDepo}
              depoData={this.editDepoData}
            />
          </Dialog>
          {this.state.index === 3 && <LinkQR />}
          {this.state.index === 6 && <AddDepo />}
          {this.state.index === 7 && <DepoTable em={em} />}
          {this.state.index === 2 && (
            <>
              {this.state.orders && !this.state.loading ? (
                <Table
                  fetchOrdersData={this.fetchOrdersData}
                  orders={this.state.orders}
                />
              ) : (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
