import { AdminType } from "./constant";
var events = require("events");

class AdminAuth {
  static instance: AdminAuth;
  isLoggedIn: boolean;
  adminType: AdminType;
  token: string | undefined;
  depos: Array<any>;
  eventEmitter = new events.EventEmitter();
  constructor() {
    this.isLoggedIn = false;
    this.adminType = AdminType.Admin;
    this.depos = [];
  }
  static getInstance() {
    if (!AdminAuth.instance) {
      AdminAuth.instance = new AdminAuth();
    }
    return AdminAuth.instance;
  }

  setAuth(val: boolean) {
    this.isLoggedIn = val;
  }
  setAdminType(val: AdminType) {
    this.adminType = val;
  }

  getAuth() {
    return this.isLoggedIn;
  }
}

export default AdminAuth;
