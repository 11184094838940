import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const qrCategories = [{ label: "DTC", value: "DTC" }];
export default function QRDialog(props: any) {
  const [number, setNumber] = React.useState(0);
  const [copy, setCopy] = React.useState(0);
  const [qrCategory, setQrCategory] = React.useState("DTC");
  console.log(props.history);
  const handleQR = () => {
    props.history.push({
      pathname: "qrs",
      state: { number, copy, qrCategory },
    });
  };
  return (
    <>
      <Dialog
        style={{
          fontFamily: "inherit",
        }}
        open={props.open}
        onClose={props.handleClose}
      >
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DialogContentText
              style={{
                fontFamily: "inherit",
              }}
            >
              Generate QR codes
            </DialogContentText>
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Select qr type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={qrCategory}
                label="Select qr type"
                onChange={(e) => {
                  setQrCategory(e.target.value);
                }}
              >
                {qrCategories.map((qr: any) => (
                  <MenuItem value={qr.value}>{qr.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="No of QR code"
            type="number"
            fullWidth
            style={{
              fontFamily: "inherit",
            }}
            onChange={(e) => {
              setNumber(parseInt(e.target.value));
            }}
            value={number}
            variant="standard"
          />
          <TextField
            margin="dense"
            id="name"
            label="No of copy for QR code"
            type="number"
            fullWidth
            style={{
              fontFamily: "inherit",
            }}
            onChange={(e) => {
              setCopy(parseInt(e.target.value));
            }}
            value={copy}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: "inherit",
            }}
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              fontFamily: "inherit",
            }}
            onClick={handleQR}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
