import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { addDepo, assignDepoToAdmin, linkQrWithVehicle } from "../api";
import QrDetail from "../components/QrDetails";

const AssignToEmail = (props: any) => {
  const [email, setEmail] = useState("");

  const [vehicleId, setVehicleId] = useState("");
  const [open, setOpen] = useState(false);
  const [qrdata, setQrData] = useState(null);
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setEmail("");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      const mapDepos = props.deposData.map((depo: any) => depo.original._id);
      console.log(props,mapDepos);

      const res = await assignDepoToAdmin(mapDepos, email);
      if (res?.isSuccess) {
        toast.success("Depo assigned to " + email);
        reset();
      } else {
        toast.error("Failed to assigned depo");
      }
      setLoading(false);
      setVehicleId("");
    } else {
      return;
    }
  };
  return (
    <div
      className="auth-container"
      style={{
        backgroundImage: "linear-gradient(to left, #fff,#fff)",
      }}
    >
      <form className="form" style={{
        width:'100%'
      }} onSubmit={handleSubmit}>
        <p className="form-title">Assign Depo</p>
        <input
          placeholder="Enter emails"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type={"email"}
          value={email}
          required
        />
        <button>
          {loading ? <CircularProgress size={17} color="inherit" /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default AssignToEmail;
