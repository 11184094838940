import { Button } from "@mui/material";
import moment from "moment";
import AdminAuth from "../../utility/auth";
export const COLUMNS = [
  {
    Header: "s.no",
    Cell: (props) => <p>{parseInt(props.row.id) + 1}</p>, //RDT provides index by default
  },
  {
    Header: "DEPOT NAME",
    Cell: (props) => {
      return props.row.original.name;
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "DEPOT ADDRESS",
    accessor: "address",
  },
  {
    Header: "DEPOT phoneNumber",
    accessor: "phoneNumber",
  },
  {
    Header: "DEPOT contactPerson",
    accessor: "contactPerson",
  },
  {
    Header: "DEPOT emails",
    accessor: "emails",
    Cell: (props) => {
      return (
        <div>
          {props.row.original.emails.map((email, i) => {
            return <p>{email}</p>;
          })}
        </div>
      );
    },
  },
  {
    Header: "DEPOT CREATED AT",
    accessor: "createdAt",
    Cell: (props) => {
      return moment(props.row.original.createdAt).format("DD-MM-YYYY hh:mm A");
    },
  },
  {
    Header: "Action",
    // accessor: "createdAt",
    Cell: (props) => {
      return (
        <Button
          onClick={() => {
            AdminAuth.getInstance().eventEmitter.emit(
              "showDepoEdit",
              props.row.original
            );
          }}
          variant="contained"
        >
          edit
        </Button>
      );
    },
  },
];
