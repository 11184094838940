import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  makeStyles,
  Button,
} from "@material-ui/core";
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import { IoArrowDown, IoArrowUp } from "react-icons/io5";
import { COLUMNS } from "./Column";
import baseUrl from "../../api/baseUrl";
import "../../styles/table.css";
import TableHeaderAction from "./TableHeaderAction";
import { Checkbox } from "./CheckBox";
import { Dialog } from "@mui/material";
import AssignToEmail from "../../pages/AssignToEmail";

const DepoTable = (props) => {
  const csvDataRef = useRef([]);
  const useStyles = makeStyles({
    container: {
      minHeight: 250,
      maxHeight: 650,
    },
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [clickedRow, setClickedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [PageCount, setPageCount] = useState(-1);

  function reset() {
    console.log("dd");
    setData([]);
    setSearchText("");
    setDateRange(null);
    fetchData();
    setPagination({ pageIndex: 0, pageSize: 10 });
    setPageCount(-1);
    csvDataRef.current = [];
  }

  const fetchData = async (page, limit = 10) => {
    setLoading(true);
    try {
      const res = await baseUrl.get(
        `/depo/allDepo/pageNo/${pagination.pageIndex}/pageSize/${limit}`
      );
      if (res.data.isSuccess) {
        setPageCount(res.data.data.size);
        setLoading(false);
        setData(res.data.data.depos);
      }
      console.log(res.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const renderToggleIcon = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return (
          <IoArrowDown
            color="#fff"
            onClick={() => column.toggleSortBy(false)}
          />
        );
      } else {
        return (
          <IoArrowUp color="#fff" onClick={() => column.toggleSortBy(true)} />
        );
      }
    } else {
      return;
    }
  };
  const {
    getTableProps,
    page,
    rows,
    state,
    setGlobalFilter,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    selectedFlatRows,
    pageOptions,
  } = useTable(
    {
      columns: COLUMNS,
      data,
      state: {
        pagination,
      },
      initialState: {
        sortBy: [],
        pageSize: 11,
      },
      pageCount: Math.ceil(PageCount / 10),
      manualPagination: true,
      onPaginationChange: setPagination,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );
  const { globalFilter, pageIndex, pageSize } = state;
  useEffect(() => {
    props.em.addListener("onDepoRefresh", reset);
  }, []);
  useEffect(() => {
    csvDataRef.current = [];
    fetchData();
  }, [pagination.pageIndex, searchText, dateRange]);

  const createRowsFromFilter = (row) => {
    csvDataRef.current.push(row.values);
    prepareRow(row);
    return (
      <TableRow
        hover
        onClick={() => {
          setClickedRow(row);
        }}
        {...row.getRowProps()}
      >
        {row.cells.map((cell) => {
          return (
            <TableCell {...cell.getCellProps()}>
              {cell.render("Cell")}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const handleAssignToAdmin = async () => {
    try {
      setOpen(true);
    } catch (error) {}
  };
  const handleclose = async () => {
    try {
      setOpen(false);
    } catch (error) {}
  };

  console.log("rows", selectedFlatRows);
  return (
    <>
      <div className="table__wrap">
        {/* <div
          className="table__header-action-container"
          // style={{ pointerEvents: "none" }}
        >
          <TableHeaderAction
            csvData={csvDataRef.current}
            reset={reset}
            setSearchText={setSearchText}
            searchText={searchText}
            setDateRange={setDateRange}
            dateRange={dateRange}
            setPagination={setPagination}
          />
        </div> */}
        {data && data.length > 0 && !loading ? (
          <TableContainer
            style={{ height: "90% !important" }}
            className={classes.container}
          >
            <Table stickyHeader className="table" {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => {
                  return (
                    <TableRow
                      className="table__header-row"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column, i) => {
                        return (
                          <TableCell key={i} {...column.getHeaderProps()}>
                            {column.render("Header")}
                            <span>{renderToggleIcon(column)}</span>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {page.map((row) => {
                  return createRowsFromFilter(row);
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
        {selectedFlatRows.length > 0 && (
          <div className="table_footer-Btn">
            <Button variant="contained" onClick={handleAssignToAdmin}>
              assign to
            </Button>
          </div>
        )}{" "}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            alignItems: "center",
            margin: 0,
          }}
        >
          <Button
            onClick={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex - 1,
              });
            }}
            disabled={pagination.pageIndex <= 0}
            variant="outlined"
          >
            Prev
          </Button>
          <div>
            <p>
              {pagination.pageIndex + 1} of {pageOptions.length}
            </p>
          </div>
          <Button
            style={{
              fontWeight: "600",
            }}
            onClick={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex + 1,
              });
            }}
            disabled={
              pagination.pageIndex + 1 >= PageCount / pagination.pageSize
            }
            variant="outlined"
          >
            Next
          </Button>
        </div>
        <Dialog
          style={{
            fontFamily: "inherit",
          }}
          open={open}
          onClose={handleclose}
        >
          <AssignToEmail deposData={selectedFlatRows} />
        </Dialog>
      </div>
    </>
  );
};

export default DepoTable;
