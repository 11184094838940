import AdminAuth from "../utility/auth";
import baseUrl from "./baseUrl";

export const fetchOrders = async () => {
  try {
    const res = await baseUrl.post("/admin/fetchOrders");
    console.log(res.data);
    return res.data.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const addHome = async (data: any) => {
  try {
    const res = await baseUrl.post("/addNewHome", data);
    console.log(res.data);
    return res.data.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const signUpUser = async (data: any) => {
  try {
    const res = await baseUrl.post("/signUpUser", data);
    console.log(res.data);
    return res.data.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const addSampleOTP = async (data: any) => {
  try {
    const res = await baseUrl.post("/admin/addSampleOTP", data);
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const addDevice = async (data: any) => {
  try {
    const res = await baseUrl.post("/igloo/addDevice", data);
    console.log(res.data);
    return res.data.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const getQrCodes = async (numberOfQrCodes: number) => {
  try {
    const res = await baseUrl.post("/genrateQRCodes", {
      numberOfQrCodes,
    });
    console.log("getQrCodes", res.data);
    return res.data.data[0];
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};

export const linkQrCodeWithHome = async (
  qrCodeId: string,
  homeId: string,
  linkedBy: string
) => {
  try {
    const res = await baseUrl.post("/linkQRCodeWithHome", {
      qrCodeId,
      homeId,
      linkedBy,
    });
    console.log("linkQrCodeWithHome", res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const getQrDetail = async (qrid: string) => {
  try {
    const res = await baseUrl.get(`/vehicleQR/getDetailsByQrShortId/${qrid}`);
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const addVehicle = async (vehicleNumber: string, depo: string) => {
  try {
    const res = await baseUrl.post(`/vehicle/addVehicle/`, {
      vehicleNumber,
      vehicleState: "DL",
      depo,
    });
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const editVehicle = async (
  vehicleNumber: string,
  depo: string,
  _id: string
) => {
  try {
    const res = await baseUrl.put(`/vehicle/editVehicle/`, {
      vehicleNumber,
      vehicleState: "DL",
      depo,
      _id,
    });
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const getQrDetailByShortId = async (qrid: string) => {
  try {
    const res = await baseUrl.get(`/qrcode/qrCodeDetailByShortId/${qrid}`);
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const getVehicleById = async (vehicleId: string) => {
  try {
    const res = await baseUrl.get(`/vehicle/vehicleByNo/${vehicleId}`);
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const addDepo = async (
  name: string,
  address: string,
  contactPerson: string,
  phoneNumber: string,
  emails: any
) => {
  try {
    const res = await baseUrl.post(
      `/depo/addDepo`,
      {
        name,
        address,
        contactPerson,
        phoneNumber,
        emails,
      },
      {
        headers: {
          Authorization: AdminAuth.getInstance().token!,
        },
      }
    );
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const editDepo = async (
  name: string,
  address: string,
  contactPerson: string,
  phoneNumber: string,
  emails: any,
  _id: string
) => {
  try {
    const res = await baseUrl.put(
      `/depo/editDepo`,
      {
        name,
        address,
        contactPerson,
        phoneNumber,
        emails,
        _id,
      },
      {
        headers: {
          Authorization: AdminAuth.getInstance().token!,
        },
      }
    );
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const getAllDepo = async () => {
  try {
    const res = await baseUrl.get(`/depo/allDepo`, {
      headers: {
        Authorization: AdminAuth.getInstance().token!,
      },
    });
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const assignDepoToAdmin = async (depos: any, _id: string) => {
  try {
    const res = await baseUrl.put(
      `/admin/assignDepoToAdmin`,
      {
        depos,
        email: _id,
      },
      {
        headers: {
          Authorization: AdminAuth.getInstance().token!,
        },
      }
    );
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const linkQrWithVehicle = async (qrCode: string, vehicle: string) => {
  try {
    const qrdata = await getQrDetailByShortId(qrCode);
    const vehicledata = await getVehicleById(vehicle);
    if (qrdata.data && qrdata.data._id && vehicledata) {
      const res = await baseUrl.post(`/vehicleQR/assignVehicleWithQR/`, {
        qrCode: qrdata.data._id,
        vehicle: vehicledata.data._id,
        qrCodeType: "A",
      });
      if (res.data && res.data.data) {
        return res.data;
      } else {
        return "Vehicle Not Exist";
      }
    } else {
      return "Qr code or Vehicle not found";
    }
  } catch (error: any) {
    console.log(error.response.data);
    return error.response.data.message ?? "something went wrong";
  }
};
export const deactivateQr = async (qrid: string) => {
  try {
    const res = await baseUrl.post("/admin/deactivateQRCode", {
      shortId: qrid,
    });
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
export const getAllBreakDown = async (qrid: string) => {
  try {
    const res = await baseUrl.get(
      "/breakDownInfo/allBreakDowns/searchBy/-1/startDate/-1/endDate/-1"
    );
    console.log(res.data);
    return res.data;
  } catch (error: any) {
    console.log(error.response.data);
    return false;
  }
};
