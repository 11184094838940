import React, { useEffect, useState } from "react";
import { IoReload, IoSearchSharp } from "react-icons/io5";
import { FiFilter } from "react-icons/fi";
import {
  FormControl,
  Select,
  InputLabel,
  Button,
  CircularProgress,
} from "@material-ui/core";
import DatePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { CSVLink, CSVDownload } from "react-csv";
import { read, writeFileXLSX, utils } from "xlsx";
import { getAllBreakDown } from "../../api";
import moment from "moment";

const TableHeaderAction = ({
  filter,
  setGlobalFilter,
  setFilter,
  setDateRangeFilter,
  setStatusFilterValue,
  fetchOrdersData,
  csvData,
  reset,
  setDateRange,
  dateRange,
  searchText,
  setSearchText,
  setPagination,
}) => {
  const [csvDataState, setcsvDataState] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // console.log("redux", dateRange);
    // if (!dateRange) {
    //   setDateRangeFilter(null);
    //   setFilter("createdAt", null);
    // } else {
    //   setDateRangeFilter(dateRange);
    // }
  }, [dateRange]);
  const onDownload = (event, done) => {
    /* global BigInt */
    const data_to_download = [];
    if (csvData) {
      csvData.forEach((data) => {
        const isActivated = [];
        data.homes.forEach((home) => {
          home.qrCodes.forEach((qr) => {
            return isActivated.push(qr.isActivated);
          });
        });
        console.log("isActivated", isActivated);
        data_to_download.push({
          name: data.name,
          phoneNumber: BigInt(data.phoneNumber),
          QRCode: String(isActivated),
          isLoggedIn: data.isLoggedIn,
          createdAt: data.createdAt,
        });
      });
    }
    console.log(data_to_download);
    if (data_to_download.length) {
      setcsvDataState(data_to_download);
      done(true);
    }
  };

  const handleCsv = async () => {
    setLoading(true);
    const res = await getAllBreakDown();
    console.log("getAllBreakDown", res);
    const dataToExcel = [];
    res.data?.breakDowns?.forEach((breakdown, i) => {
      const csv = {
        s_no: i + 1,
        Breakdown_Time: moment(breakdown.breakdownTime).format(
          "DD-MM-YYYY HH:MM a"
        ),
        Vehicle_No: breakdown.vehicle.vehicleNumber,
        Lat_Long: `${breakdown.latitude ?? breakdown.latitudeFromDevice},${
          breakdown.longitude ?? breakdown.longitudeFromDevice
        }`,
        Map_Link: `https://www.google.com/maps/search/?api=1&query=${
          breakdown.latitude ?? breakdown.latitudeFromDevice
        }%2C${breakdown.longitude ?? breakdown.longitudeFromDevice}`,
      };
      dataToExcel.push(csv);
    });
    // return;
    console.log("csv", csvData);
    const ws = utils.json_to_sheet(dataToExcel);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, "BreakdownData.xlsx");
    setLoading(false);
  };

  return (
    <>
      <div className="table__header-action">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="table__form-box">
            <IoSearchSharp size={22} color="#000" />
            <input
              value={searchText || ""}
              onChange={(e) => {
                setSearchText(e.target.value);
                setPagination({ pageIndex: 0, pageSize: 10 });
              }}
              type="text"
              placeholder="Search By Vehicle Number.."
            />
          </div>
        </form>
        <div className="table__date-range">
          <DatePicker
            value={dateRange}
            onChange={(newDate) => {
              console.log("newdate", newDate);
              setDateRange(newDate);
              setPagination({ pageIndex: 0, pageSize: 10 });
            }}
          />
          <Button
            style={{
              fontWeight: "600",
            }}
            variant="contained"
            onClick={handleCsv}
          >
            {loading   ? <CircularProgress size={30} color="inherit" /> : "Export to csv"}
          </Button>
        </div>
        <div className="table__header-icons">
          <IoReload onClick={reset} size={25} cursor="pointer" />
        </div>
      </div>
    </>
  );
};

export default TableHeaderAction;
