import axios from "axios";
import AdminAuth from "../utility/auth";

const uris = {
  dev: "https://dev.api.doorvi.co",
  production: "https://dtc.doorvi.co:4022",
  callProductionUri: "https://dtc.doorvi.co/?vm=",
  callDevUri: "https://dev.call.doorvi.co/?vm=",
};

export default axios.create({
  baseURL: `${uris.production}`,
});

export { uris };
